<template>
  <section>
    <h1>{{ $t(`security.resetPassword.titles.${passwordModificationType}`) }}</h1>

    <p>
      <v-icon>mdi-information-outline</v-icon>
      {{ $t('security.passwordDetails') }}
    </p>

    <v-form ref="resetForm" v-model="valid" lazy-validation>
      <v-text-field v-model="password.old"
                    v-if="oldPasswordDisplayed"
                    :type="passwordOldVisible"
                    :label="$t('security.resetPassword.inputs.old')"
                    :rules="[password.rules.required,]"
                    ref="oldPassword"
                    required
                    solo
      >
        <template v-slot:append>
          <v-icon @click="password.visible.old = !password.visible.old" tabindex="2">{{ passwordOldIcon }}</v-icon>
        </template>
      </v-text-field>

      <v-text-field v-model="password.one"
                    :type="passwordOneVisible"
                    :label="$t('security.resetPassword.inputs.password1')"
                    :rules="[password.rules.required,
                             password.rules.counter,
                             password.rules.upper,
                             password.rules.lower,
                             password.rules.numeric,
                             password.rules.special,
                            ]"
                    ref="password1"
                    required
                    solo
      >
        <template v-slot:append>
          <v-icon @click="password.visible.one = !password.visible.one" tabindex="2">{{ passwordOneIcon }}</v-icon>
        </template>
      </v-text-field>

      <v-text-field v-model="password.two"
                    :type="passwordTwoVisible"
                    :label="$t('security.resetPassword.inputs.password2')"
                    :rules="[password.rules.required,
                             password.rules.counter,
                             password.rules.upper,
                             password.rules.lower,
                             password.rules.numeric,
                             password.rules.special,
                            ]"
                    ref="password2"
                    required
                    solo
      >
        <template v-slot:append>
          <v-icon @click="password.visible.two = !password.visible.two" tabindex="2">{{ passwordTwoIcon }}</v-icon>
        </template>
      </v-text-field>

      <footer>
        <v-btn @click.prevent="onSubmit" type="submit" color="success" ref="reset_form_submit">
          {{ $t('security.resetPassword.button') }}
        </v-btn>
      </footer>
    </v-form>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import i18n from '@/plugins/i18n'

export default {
  name: 'ResetPassword',

  data: function () {
    return {
      password: {
        one: '',
        old: '',
        two: '',
        rules: {
          counter: v => v.length >= 8 || this.$t('general.inputErrors.tooShort', { n: '8' }),
          lower: v => /[a-z]+/.test(v) || this.$t('security.resetPassword.alert.nonLower'),
          numeric: v => /[0-9]+/.test(v) || this.$t('security.resetPassword.alert.nonNumeric'),
          special: v => /[*\-+=.,;:!?&"'()_[\]\\{}@%#$<>]+/.test(v) || this.$t('security.resetPassword.alert.nonSpecialCar'),
          required: v => !!v || this.$t('general.inputErrors.required'),
          upper: v => /[A-Z]+/.test(v) || this.$t('security.resetPassword.alert.nonUpper'),
        },
        visible: {
          one: false,
          old: false,
          two: false,
        },
      },
      valid: false,
    }
  },

  computed: {
    ...mapGetters([
        'accessJWTBody'
    ]),
    isPasswordExpired () {
      return this.accessJWTBody.hasExpiredPassword
    },
    isPasswordInit () {
      return this.accessJWTBody.hasTempPassword
    },
    isPasswordRecovery () {
      return this.$route.query.token
    },
    isPasswordUpdate () {
      return !this.isPasswordExpired && !this.isPasswordInit && !this.isPasswordRecovery
    },
    oldPasswordDisplayed () {
      return this.isPasswordExpired || this.isPasswordUpdate
    },
    passwordModificationType () {
      return this.isPasswordExpired
             ? 'passwordExpired'
             : this.isPasswordInit
               ? 'passwordInit'
               : this.isPasswordRecovery
                 ? 'passwordRecovery'
                 : 'passwordUpdate'
    },
    passwordOldIcon () {
      return this.password.visible.old ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
    },
    passwordOldVisible () {
      return this.password.visible.old ? 'text' : 'password'
    },
    passwordOneIcon () {
      return this.password.visible.one ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
    },
    passwordOneVisible () {
      return this.password.visible.one ? 'text' : 'password'
    },
    passwordTwoIcon () {
      return this.password.visible.two ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
    },
    passwordTwoVisible () {
      return this.password.visible.two ? 'text' : 'password'
    },
  },

  created () {
    if (this.isPasswordExpired) {
      this.displaySnackbar({
        status: true,
        type: 'info',
        text: this.$t('security.resetPassword.alert.passwordExpired'),
      })
    }
  },

  methods: {
    ...mapActions([
      'displaySnackbar',
      'resetPassword',
    ]),
    onSubmit () {
      if (this.$refs.resetForm.validate()) {

        this.password.one === this.password.two
        ? this.resetPassword({
          passwordModificationType: this.passwordModificationType,
          credentials: {
            password1: this.password.one,
            password2: this.password.two,
            oldPassword: this.password.old,
            id: this.accessJWTBody.userId,
          },
          token: this.$route.query.token ? this.$route.query.token : '',
        })
        : this.displaySnackbar({
          status: true,
          type: 'error',
          text: i18n.t('security.resetPassword.alert.samePassword'),
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/style/backgroundPage";

section {
  footer {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
