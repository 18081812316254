var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('h1',[_vm._v(_vm._s(_vm.$t(`security.resetPassword.titles.${_vm.passwordModificationType}`)))]),_c('p',[_c('v-icon',[_vm._v("mdi-information-outline")]),_vm._v(" "+_vm._s(_vm.$t('security.passwordDetails'))+" ")],1),_c('v-form',{ref:"resetForm",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.oldPasswordDisplayed)?_c('v-text-field',{ref:"oldPassword",attrs:{"type":_vm.passwordOldVisible,"label":_vm.$t('security.resetPassword.inputs.old'),"rules":[_vm.password.rules.required,],"required":"","solo":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"tabindex":"2"},on:{"click":function($event){_vm.password.visible.old = !_vm.password.visible.old}}},[_vm._v(_vm._s(_vm.passwordOldIcon))])]},proxy:true}],null,false,672891657),model:{value:(_vm.password.old),callback:function ($$v) {_vm.$set(_vm.password, "old", $$v)},expression:"password.old"}}):_vm._e(),_c('v-text-field',{ref:"password1",attrs:{"type":_vm.passwordOneVisible,"label":_vm.$t('security.resetPassword.inputs.password1'),"rules":[_vm.password.rules.required,
                           _vm.password.rules.counter,
                           _vm.password.rules.upper,
                           _vm.password.rules.lower,
                           _vm.password.rules.numeric,
                           _vm.password.rules.special,
                          ],"required":"","solo":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"tabindex":"2"},on:{"click":function($event){_vm.password.visible.one = !_vm.password.visible.one}}},[_vm._v(_vm._s(_vm.passwordOneIcon))])]},proxy:true}]),model:{value:(_vm.password.one),callback:function ($$v) {_vm.$set(_vm.password, "one", $$v)},expression:"password.one"}}),_c('v-text-field',{ref:"password2",attrs:{"type":_vm.passwordTwoVisible,"label":_vm.$t('security.resetPassword.inputs.password2'),"rules":[_vm.password.rules.required,
                           _vm.password.rules.counter,
                           _vm.password.rules.upper,
                           _vm.password.rules.lower,
                           _vm.password.rules.numeric,
                           _vm.password.rules.special,
                          ],"required":"","solo":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"tabindex":"2"},on:{"click":function($event){_vm.password.visible.two = !_vm.password.visible.two}}},[_vm._v(_vm._s(_vm.passwordTwoIcon))])]},proxy:true}]),model:{value:(_vm.password.two),callback:function ($$v) {_vm.$set(_vm.password, "two", $$v)},expression:"password.two"}}),_c('footer',[_c('v-btn',{ref:"reset_form_submit",attrs:{"type":"submit","color":"success"},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('security.resetPassword.button'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }